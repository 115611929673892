<template>
  <v-card
    :loading="messagesLoading"
    class="mx-auto mb-5"
    width="374"
    elevation="0"
    style="border-radius: 0"
  >
    <v-card-text class="teal--text text--lighten-1 text-center"><h3>Сообщения</h3></v-card-text>

    <v-list dense>
      <v-list-group
        color="teal"
        v-model="listActive"
        prepend-icon="mdi-calendar-expand-horizontal"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Задать диапазон дат</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item class="pl-4">

          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDateFiltered"
                label="От"
                color="teal lighten-1"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              color="teal lighten-1"
              locale="RU-ru"
              v-model="fromDate"
              @input="menu = false"
              :allowed-dates="allowedDates"
            ></v-date-picker>
          </v-menu>
        </v-list-item>

        <v-list-item class="pl-4">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDateFiltered"
                color="teal lighten-1"
                label="До"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="toDate"
              @input="menu2 = false"
              color="teal lighten-1"
              locale="RU-ru"
              :allowed-dates="allowedDates"
            ></v-date-picker>
          </v-menu>

        </v-list-item>

        <v-list-item class="pl-4">
          <v-row justify="space-around">
            <v-btn @click="showEventsForDates()" dark color="teal lighten-1">Показать</v-btn>
            <v-btn @click="clearDatesRange()">Очистить</v-btn>
          </v-row>
        </v-list-item>

      </v-list-group>
    </v-list>

    <v-text-field
      v-model="filter"
      prepend-icon="mdi-account-search"
      label="Поиск"
      color="teal lighten-1"
    ></v-text-field>

    <div style="display: flex; justify-content: space-evenly">
      <v-checkbox v-model="showDelivered" label="Доставленные"></v-checkbox>
      <v-checkbox v-model="showNotDelivered" label="Недоставленные"></v-checkbox>
    </div>

    <div style="display: flex; justify-content: space-evenly">
      <v-btn
        @click="incomingTrigger = false"
        :dark="!incomingTrigger"
        :color="!incomingTrigger ? 'teal lighten-1' : ''"
      >Исходящие
      </v-btn>
      <v-btn
        @click="incomingTrigger = true"
        :dark="incomingTrigger"
        :color="incomingTrigger ? 'teal lighten-1' : ''"
      >Входящие
      </v-btn>
    </div>

    <v-alert
      v-for="message in filteredMessages(filter)"
      :key="message._id"
      class="pl10 mt30 mx-1 elevation-5 rounded"
      :lodaing="messagesLoading"
      outlined
      :type="message.delivered ? 'success' : 'warning'"
      text
    >

      <v-card-text style="margin-top: -20px">
        <div class="text-right" style="font-size: 12px">
          <small class="grey--text lighten-4">{{ message.message.create_date | date('datetime') }}</small>
        </div>

        <div style="margin-top: -24px">
          <div>
            <b v-if="message.delivered"
               class="mt-4 indigo--text text--accent-4"
            >
              Доставлено
            </b>
            <b v-else
               class="mt-4 red--text text--darken-3"
            >
              Не доставлено
            </b>
          </div>

          <div :class="message.delivered ? 'mt-4 indigo--text text--accent-4' : 'mt-4 red--text text--darken-3'">
            <div>Вид сообщения:
              <b>{{ message.message_kind.toUpperCase() }} </b>
              <a v-if="message.record" :href="message.record">
                <v-icon style="margin-top: -4px">mdi-volume-high</v-icon>
              </a>
            </div>
            <div>Провайдер: <b>{{ message.provider }}</b></div>

          </div>

          <div v-if="message.delivered">
            <b>Дата и время доставки: </b>{{ message.delivered_date | date('datetime') }}
          </div>

          <div v-if="message.message.to_subscriber"><b>Телефон: </b>{{ message.message.to_subscriber.phone }}</div>

          <div v-if="message.message.to_subscriber">
            <b>Кому: </b>
            {{ message.message.to_subscriber.firstName }}
            {{ message.message.to_subscriber.lastName }}
          </div>
          <div v-if="message.message.to_user">
            <b>Кому: </b>
            {{ message.message.to_user.firstName }}
            {{ message.message.to_user.lastName }}
          </div>

          <div>
            <b>Заголовок: </b>
            {{ message.message.message_title }}
          </div>

          <div>
            <b>Сообщение: </b>
             <span v-html="message.message.message_text"></span>
          </div>
        </div>

      </v-card-text>
    </v-alert>
  </v-card>
</template>

<script>

import dateFilter from "@/filters/dateFilter"

export default {
  name: "NotificationsForClients",
  data: () => ({
    loading: false,
    messagesLoading: false,
    listActive: false,
    menu: false,
    menu2: false,
    fromDate: "",
    toDate: "",
    filter: "",
    messages: [],
    incomingTrigger: false,
    showDelivered: true,
    showNotDelivered: false
  }),

  computed: {
    fromDateFiltered: {
      get: function () {
        return dateFilter(this.fromDate)
      },
      set: function (date) {
        this.fromDate = date
      }
    },

    toDateFiltered: {
      get: function () {
        return dateFilter(this.toDate)
      },
      set: function (date) {
        this.toDate = date
      }
    },

    // incomingOutgoingMessagesFilter() {
    //   if (!this.messages.length) return []
    //   if (this.incomingTrigger) return this.filteredMessages(this.filter).filter(message => !!message.message.to_user)
    //   if (!this.incomingTrigger) return this.filteredMessages(this.filter).filter(message => !!message.message.to_subscriber)
    // },
    //
    // outgoingMessages() {
    //   console.log('this.messages.length', this.messages.length)
    //   if (!this.messages.length) return []
    //   return this.filteredMessages(this.filter).filter(message => !!message.message.to_subscriber)
    // }
  },

  methods: {
    async loadNotificationsList() {
      this.messagesLoading = true
      const json = await this.authFetch('/message/get-all-messages-for-dates-or-for-last-three-days', {})
      delete json.status
      let messages = []
      Object.keys(json).forEach(key => messages.push(...json[key]))


      // Array.from(json).forEach(array => {
      //   messages.push(...array)
      // })

      // console.log(json)
      // console.log(Object.keys(json))
      // Object.keys(json).forEach(key => {
      //   console.log(json[key])
      //   messages.push(...json[key])
      //   console.log(messages)
      // })
      this.messages = messages

      this.messages.sort(this.sortMessagesFunc)
      this.messagesLoading = false
    },

    filteredMessages(str) {
      if (!this.messages.length) return []
      // if (!this.filter) return this.messages

      let filtered = this.messages
        .map(message => JSON.stringify({...message}))
        .filter(j => j.toLowerCase().includes(str.toLowerCase()))
        .map(j => JSON.parse(j))

      return this.messages
        .filter(message => filtered
          .map(filteredOne => filteredOne._id)
          .includes(message._id))

        .map(message => {
          message.message.message_text =
            message.message.message_text.split('\n').join('<br>')
          return message
        })

        .filter(message => {
          if (this.incomingTrigger) return !!message.message.to_user
          if (!this.incomingTrigger) return !!message.message.to_subscriber
        })

        .filter(message => {
          return !(!this.showDelivered && message.delivered)
        })

        .filter(message => {
          return !(!this.showNotDelivered && !message.delivered)
        })
    },

    clearDatesRange() {
      this.fromDate = ""
      this.toDate = ""
      this.loadEventsList()
    },

    async showEventsForDates() {
      if (!this.fromDate && !this.toDate) return

      this.messagesLoading = true
      let toDate = this.toDate || dateFilter(Date.now(), 'ISO')
      this.toDate = toDate
      let fromDate = this.fromDate || toDate
      this.fromDate = fromDate
      if (toDate < fromDate) {
        [toDate, fromDate] = [fromDate, toDate]
        this.toDate = toDate
        this.fromDate = fromDate
      }
      const json = await this.authFetch('/message/get-all-messages-for-dates-or-for-last-three-days', {
        fromDate,
        toDate
      })
      delete json.status
      let messages = []
      Object.keys(json).forEach(key => messages.push(...json[key]))
      this.messages = messages

      this.messages.sort(this.sortMessagesFunc)
      this.messagesLoading = false
    },

    allowedDates(val) {
      return (new Date(Date.now()) > new Date(val))
    }
  },

  async mounted() {
    await this.loadNotificationsList()
  }

}
</script>

<style scoped>

</style>

